import React, { useState } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Header from './Header'
import Footer from "./Footer"
import ContactForm from './Contact'
// import { useMediaQuery } from 'react-responsive'
// import LoadingScreen from '../LoadingScreen'
// import useIsMounted from '../../utils/useIsMounted';

const Wrapper = ({ children }) => {
	const data = useStaticQuery(graphql`
    query SiteDataQuery {
      site {
		  siteMetadata {
            title
            brand
            menuLinks {
              name
              link
  			linkClass
  			partActive
            }
          }
      }
    }
  `)

  const [contactOpen, contactIsOpen] = useState(false);
  // const toggleContact = () => contactIsOpen(!contactOpen);

  const toggleContact = () => contactIsOpen(contactOpen => !contactOpen);

  const wrapStyle = {
    transition: 'all 200ms ease-in-out',
    opacity: contactOpen ? '0.2' : '1.0',
  }

  // const [mounted, setMounted] = useState(false);
  // useEffect(() => {
  //     setMounted(true)
  // }, [])

  // const isMounted = useIsMounted()

  return (
    <div>


   
    <div>

      <div style={wrapStyle}>
       
      {/* {isMounted && <LoadingScreen />} */}

      <Header menuLinks={data.site.siteMetadata?.menuLinks} siteBrand={data.site.siteMetadata?.brand} siteTitle={data.site.siteMetadata?.title} contactToggle={toggleContact} contactOpen={contactOpen} />
      {children}
      <Footer menuLinks={data.site.siteMetadata?.menuLinks} siteTitle={data.site.siteMetadata?.title} siteBrand={data.site.siteMetadata?.brand} contactToggle={toggleContact} />
      
      </div>

    </div>
    <ContactForm toggleContact={contactOpen} isContact={contactIsOpen} toggle={toggleContact}  />
    
	</div>
  );
}

export default Wrapper;
