import React from 'react'

import './src/css/tailwind.scss';

import Wrapper from './src/components/layout/Wrapper';

export const wrapPageElement = ({ element, props }) => (
  <Wrapper {...props}>{element}</Wrapper>
);


// export const onClientEntry = () => {
//   setTimeout(() => {
//     if (document.getElementById("hohn-loader")) {
//       document.getElementById("hohn-loader").style.display = "block";
//       document.querySelector("html").style.overflow = "hidden"
//     }
//   }, 0);
// };

// export const onInitialClientRender = () => {
//   setTimeout(() => {
//     if (document.getElementById("hohn-loader")) {
//       document.getElementById("hohn-loader").style.display = "block";
//       document.querySelector("html").style.overflow = "hidden"
//     }
//   }, 0);
// };

// export const onRouteUpdate = () => {
//   setTimeout(() => {
//     if (document.getElementById("hohn-loader")) {
//       document.getElementById("hohn-loader").style.display = "none";
//       document.querySelector("html").style.overflow = "unset"
//     }
//   }, 3600);
// };


